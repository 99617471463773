import classesToSelector from '../../shared/classes-to-selector.js';
import $ from '../../shared/dom.js';

export default function A11y({ swiper, extendParams, on }) {
  extendParams({
    a11y: {
      enabled: true,
      notificationClass: 'swiper-notification',
      prevSlideMessage: 'Previous slide',
      nextSlideMessage: 'Next slide',
      firstSlideMessage: 'This is the first slide',
      lastSlideMessage: 'This is the last slide',
      paginationBulletMessage: 'Go to slide {{index}}',
      slideLabelMessage: '{{index}} / {{slidesLength}}',
      containerMessage: null,
      containerRoleDescriptionMessage: null,
      itemRoleDescriptionMessage: null,
      slideRole: 'group',
      id: null,
    },
  });

  let liveRegion = null;

  function notify(message) {
    const notification = liveRegion;
    if (notification.length === 0) return;
    notification.html('');
    notification.html(message);
  }

  function getRandomNumber(size = 16) {
    const randomChar = () => Math.round(16 * Math.random()).toString(16);
    return 'x'.repeat(size).replace(/x/g, randomChar);
  }
  function makeElFocusable($el) {
    $el.attr('tabIndex', '0');
  }
  function makeElNotFocusable($el) {
    $el.attr('tabIndex', '-1');
  }
  function addElRole($el, role) {
    $el.attr('role', role);
  }
  function addElRoleDescription($el, description) {
    $el.attr('aria-roledescription', description);
  }
  function addElControls($el, controls) {
    $el.attr('aria-controls', controls);
  }
  function addElLabel($el, label) {
    $el.attr('aria-label', label);
  }
  function addElId($el, id) {
    $el.attr('id', id);
  }
  function addElLive($el, live) {
    $el.attr('aria-live', live);
  }
  function disableEl($el) {
    $el.attr('aria-disabled', true);
  }
  function enableEl($el) {
    $el.attr('aria-disabled', false);
  }

  function onEnterOrSpaceKey(e) {
    if (e.keyCode !== 13 && e.keyCode !== 32) return;
    const params = swiper.params.a11y;
    const $targetEl = $(e.target);
    if (swiper.navigation && swiper.navigation.$nextEl && $targetEl.is(swiper.navigation.$nextEl)) {
      if (!(swiper.isEnd && !swiper.params.loop)) {
        swiper.slideNext();
      }
      if (swiper.isEnd) {
        notify(params.lastSlideMessage);
      } else {
        notify(params.nextSlideMessage);
      }
    }
    if (swiper.navigation && swiper.navigation.$prevEl && $targetEl.is(swiper.navigation.$prevEl)) {
      if (!(swiper.isBeginning && !swiper.params.loop)) {
        swiper.slidePrev();
      }
      if (swiper.isBeginning) {
        notify(params.firstSlideMessage);
      } else {
        notify(params.prevSlideMessage);
      }
    }

    if (
      swiper.pagination &&
      $targetEl.is(classesToSelector(swiper.params.pagination.bulletClass))
    ) {
      $targetEl[0].click();
    }
  }

  function updateNavigation() {
    if (swiper.params.loop || swiper.params.rewind || !swiper.navigation) return;
    const { $nextEl, $prevEl } = swiper.navigation;

    if ($prevEl && $prevEl.length > 0) {
      if (swiper.isBeginning) {
        disableEl($prevEl);
        makeElNotFocusable($prevEl);
      } else {
        enableEl($prevEl);
        makeElFocusable($prevEl);
      }
    }
    if ($nextEl && $nextEl.length > 0) {
      if (swiper.isEnd) {
        disableEl($nextEl);
        makeElNotFocusable($nextEl);
      } else {
        enableEl($nextEl);
        makeElFocusable($nextEl);
      }
    }
  }

  function hasPagination() {
    return swiper.pagination && swiper.pagination.bullets && swiper.pagination.bullets.length;
  }

  function hasClickablePagination() {
    return hasPagination() && swiper.params.pagination.clickable;
  }

  function updatePagination() {
    const params = swiper.params.a11y;
    if (!hasPagination()) return;
    swiper.pagination.bullets.each((bulletEl) => {
      const $bulletEl = $(bulletEl);
      if (swiper.params.pagination.clickable) {
        makeElFocusable($bulletEl);
        if (!swiper.params.pagination.renderBullet) {
          addElRole($bulletEl, 'button');
          addElLabel(
            $bulletEl,
            params.paginationBulletMessage.replace(/\{\{index\}\}/, $bulletEl.index() + 1),
          );
        }
      }
      if ($bulletEl.is(`.${swiper.params.pagination.bulletActiveClass}`)) {
        $bulletEl.attr('aria-current', 'true');
      } else {
        $bulletEl.removeAttr('aria-current');
      }
    });
  }

  const initNavEl = ($el, wrapperId, message) => {
    makeElFocusable($el);
    if ($el[0].tagName !== 'BUTTON') {
      addElRole($el, 'button');
      $el.on('keydown', onEnterOrSpaceKey);
    }
    addElLabel($el, message);
    addElControls($el, wrapperId);
  };

  const handleFocus = (e) => {
    const slideEl = e.target.closest(`.${swiper.params.slideClass}`);
    if (!slideEl || !swiper.slides.includes(slideEl)) return;
    const isActive = swiper.slides.indexOf(slideEl) === swiper.activeIndex;
    const isVisible =
      swiper.params.watchSlidesProgress &&
      swiper.visibleSlides &&
      swiper.visibleSlides.includes(slideEl);
    if (isActive || isVisible) return;
    if (swiper.isHorizontal()) {
      swiper.el.scrollLeft = 0;
    } else {
      swiper.el.scrollTop = 0;
    }
    swiper.slideTo(swiper.slides.indexOf(slideEl), 0);
  };

  const initSlides = () => {
    const params = swiper.params.a11y;
    if (params.itemRoleDescriptionMessage) {
      addElRoleDescription($(swiper.slides), params.itemRoleDescriptionMessage);
    }
    if (params.slideRole) {
      addElRole($(swiper.slides), params.slideRole);
    }

    const slidesLength = swiper.params.loop
      ? swiper.slides.filter((el) => !el.classList.contains(swiper.params.slideDuplicateClass))
          .length
      : swiper.slides.length;
    if (params.slideLabelMessage) {
      swiper.slides.each((slideEl, index) => {
        const $slideEl = $(slideEl);
        const slideIndex = swiper.params.loop
          ? parseInt($slideEl.attr('data-swiper-slide-index'), 10)
          : index;
        const ariaLabelMessage = params.slideLabelMessage
          .replace(/\{\{index\}\}/, slideIndex + 1)
          .replace(/\{\{slidesLength\}\}/, slidesLength);
        addElLabel($slideEl, ariaLabelMessage);
      });
    }
  };

  const init = () => {
    const params = swiper.params.a11y;

    swiper.$el.append(liveRegion);

    // Container
    const $containerEl = swiper.$el;
    if (params.containerRoleDescriptionMessage) {
      addElRoleDescription($containerEl, params.containerRoleDescriptionMessage);
    }
    if (params.containerMessage) {
      addElLabel($containerEl, params.containerMessage);
    }

    // Wrapper
    const $wrapperEl = swiper.$wrapperEl;
    const wrapperId = params.id || $wrapperEl.attr('id') || `swiper-wrapper-${getRandomNumber(16)}`;
    const live = swiper.params.autoplay && swiper.params.autoplay.enabled ? 'off' : 'polite';
    addElId($wrapperEl, wrapperId);
    addElLive($wrapperEl, live);

    // Slide
    initSlides();

    // Navigation
    let $nextEl;
    let $prevEl;
    if (swiper.navigation && swiper.navigation.$nextEl) {
      $nextEl = swiper.navigation.$nextEl;
    }
    if (swiper.navigation && swiper.navigation.$prevEl) {
      $prevEl = swiper.navigation.$prevEl;
    }

    if ($nextEl && $nextEl.length) {
      initNavEl($nextEl, wrapperId, params.nextSlideMessage);
    }
    if ($prevEl && $prevEl.length) {
      initNavEl($prevEl, wrapperId, params.prevSlideMessage);
    }

    // Pagination
    if (hasClickablePagination()) {
      swiper.pagination.$el.on(
        'keydown',
        classesToSelector(swiper.params.pagination.bulletClass),
        onEnterOrSpaceKey,
      );
    }

    // Tab focus
    swiper.$el.on('focus', handleFocus, true);
  };
  function destroy() {
    if (liveRegion && liveRegion.length > 0) liveRegion.remove();

    let $nextEl;
    let $prevEl;
    if (swiper.navigation && swiper.navigation.$nextEl) {
      $nextEl = swiper.navigation.$nextEl;
    }
    if (swiper.navigation && swiper.navigation.$prevEl) {
      $prevEl = swiper.navigation.$prevEl;
    }
    if ($nextEl) {
      $nextEl.off('keydown', onEnterOrSpaceKey);
    }
    if ($prevEl) {
      $prevEl.off('keydown', onEnterOrSpaceKey);
    }

    // Pagination
    if (hasClickablePagination()) {
      swiper.pagination.$el.off(
        'keydown',
        classesToSelector(swiper.params.pagination.bulletClass),
        onEnterOrSpaceKey,
      );
    }

    // Tab focus
    swiper.$el.off('focus', handleFocus, true);
  }

  on('beforeInit', () => {
    liveRegion = $(
      `<span class="${swiper.params.a11y.notificationClass}" aria-live="assertive" aria-atomic="true"></span>`,
    );
  });

  on('afterInit', () => {
    if (!swiper.params.a11y.enabled) return;
    init();
  });
  on('slidesLengthChange snapGridLengthChange slidesGridLengthChange', () => {
    if (!swiper.params.a11y.enabled) return;
    initSlides();
  });
  on('fromEdge toEdge afterInit lock unlock', () => {
    if (!swiper.params.a11y.enabled) return;
    updateNavigation();
  });
  on('paginationUpdate', () => {
    if (!swiper.params.a11y.enabled) return;
    updatePagination();
  });
  on('destroy', () => {
    if (!swiper.params.a11y.enabled) return;
    destroy();
  });
}
